import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { useEffect, useState } from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  API_DELETE,
  API_PATCH,
  API_POST,
  DBRequest,
  useConfig,
} from "../../../../api/api";
import TypeText from "../../../form/form-fields/TypeText";
import { useTranslation } from "react-i18next";
import RenderHTML from "../../../preview/RenderHTML";
import DateTimeLabel from "../../../label/DateTimeLabel";
import TypeDate from "../../../form/form-fields/TypeDate";
import DateLabel from "../../../label/DateLabel";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from "yup";
import TypeRichText from "../../../form/form-fields/TypeRichText";
import TypeImage from "../../../form/form-fields/TypeImage";
import MediaPreview from "../../explorer/MediaPreview";

export const WPEToDoElement = ({
  element,
  onChange,
  wpeId,
  onDelete,
  className,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();

  const [isEditing, setIsEditing] = useState(element.id ? false : true);
  const [isProcessing, setIsProcessing] = useState(false);

  const [dataCollection, setDataCollection] = useState(element);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setDataCollection(element);
    }
  }, [isEditing]);

  function handleToggleEditMode() {
    setIsEditing(!isEditing);
  }

  async function updateValidationOnChange(
    key,
    fieldValue,
    destructObject = false
  ) {
    if (destructObject) {
      const valueToCheck = fieldValue[key];
      validateField(key, valueToCheck);
      updateDataCollection({ ...fieldValue });
    } else {
      validateField(key, fieldValue);
      updateDataCollection({ [key]: fieldValue });
    }
  }

  // validate every field onChange!
  const validateField = async (fieldKey, fieldValue) => {
    try {
      const fieldSchema = Yup.object().shape({
        [fieldKey]: validationSchema.fields[fieldKey],
      });
      await fieldSchema.validate({ [fieldKey]: fieldValue });
      // Validation passed for the specific field
      setIsValid(true);

      // clear the error
      const newErrors = { ...errors };
      delete newErrors[fieldKey];
      setErrors(newErrors);
    } catch (err) {
      // Validation failed for the specific field
      setIsValid(false);
      if (err instanceof Yup.ValidationError) {
        setErrors({ ...errors, [fieldKey]: err.message });
      }
    }
  };

  function handleDelete() {
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(element),
    });
  }

  function handleAbortEditMode() {
    setIsEditing(!isEditing);
  }

  function handleSubmitEditMode() {
    setIsEditing(!isEditing);
    const isUpdate = Boolean(element.id);

    DBRequest({
      config,
      path: isUpdate
        ? `work-packages/elements/list/tasks/${element.id}`
        : `work-packages/elements/list/tasks`,
      method: isUpdate
        ? API_PATCH(dataCollection)
        : API_POST({ ...dataCollection, status: 0, wpeId }),
      onResponse: handleResponse,
      onLoading: setIsProcessing,
    });
  }

  function handleToggleComplete() {
    if (isEditing) return;
    if (isProcessing) return;
    element.status ? (dataCollection.status = 0) : (dataCollection.status = 1);
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_PATCH(dataCollection),
      onResponse: handleResponse,
      onLoading: setIsProcessing,
    });
  }

  function handleResponse(res) {
    onChange(res.data);
  }

  function updateDataCollection(value) {
    setDataCollection((prev) => {
      return { ...prev, ...value };
    });
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("is required")),
  });

  return (
    <Box
      className={`flex flex-col gap-2 rounded-lg  px-3 py-1 w-full ${className}`}
      sx={{
        backgroundColor: colors.bgInput,
        border: "1px solid transparent",
      }}
    >
      <Box className=" w-full flex gap-5 relative items-start justify-between pb-4">
        <Box className="h-full w-full flex flex-col justify-between items-end absolute top-1 right-1">
          {isEditing ? (
            <Box className="z-10">
              <Box>
                <IconButton
                  disabled={!isValid}
                  color={isValid ? "success" : "default"}
                  onClick={handleSubmitEditMode}
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={
                    Boolean(element.id)
                      ? handleAbortEditMode
                      : () => onChange({})
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <IconButton className="z-10" onClick={handleToggleEditMode}>
              <EditIcon />
            </IconButton>
          )}
        </Box>

        <Box className="w-[40px] h-full flex flex-col justify-between">
          <Box>
            <IconButton disabled={isEditing} onClick={handleToggleComplete}>
              {isProcessing ? (
                <span
                  className="loader "
                  style={{ width: "20px", height: "20px" }}
                />
              ) : element.status ? (
                <TaskAltIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
            </IconButton>
          </Box>

          {element.id && isEditing ? (
            <Box>
              <IconButton onClick={handleDelete} className="w-full left-0">
                <DeleteIcon />
              </IconButton>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>

        <Box className="h-full w-full flex flex-col justify-between">
          <Box className="w-[40%]">
            {isEditing ? (
              <Box className="py-4">
                <TypeText
                  size="small"
                  dataCollection={dataCollection}
                  field={{ label: t("Title"), key: "name" }}
                  updateValidationOnChange={updateValidationOnChange}
                  errors={errors}
                />
              </Box>
            ) : (
              <Typography variant="h5" fontWeight={500}>
                {element.name}
              </Typography>
            )}
          </Box>
          <Box className="w-full">
            {isEditing ? (
              <Box className="w-[80%]">
                <TypeRichText
                  dataCollection={dataCollection}
                  field={{
                    label: `${t("Description")} (${t("optional")})`,
                    key: "description",
                  }}
                  updateValidationOnChange={updateValidationOnChange}
                />
              </Box>
            ) : (
              element.description && (
                <Typography className="w-[80%]">
                  <RenderHTML HTML={element.description} />
                </Typography>
              )
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className="flex justify-between">
        <Box className="flex gap-2">
          <Tooltip
            title={t("Date created")}
            className="flex gap-1 items-center"
          >
            <MoreTimeIcon fontSize="small" />
            <Typography variant="h6">
              <DateTimeLabel dateTime={element.createdAt} />
            </Typography>
          </Tooltip>
          {isEditing ? (
            <TypeImage
              dataCollection={dataCollection}
              field={{
                label: t("File"),
                key: "file",
              }}
              errors={errors}
              updateValidationOnChange={updateValidationOnChange}
            />
          ) : (
            <>
              {element.file && (
                <MediaPreview allowPreview src={element.file?.temporaryUrl} />
              )}
            </>
          )}
        </Box>
        {isEditing ? (
          <Box className="w-[30%] flex gap-1">
            <Box className="flex gap-1 items-center">
              <Tooltip title={t("Deadline")}>
                <AccessAlarmIcon />
              </Tooltip>
              <TypeDate
                dataCollection={dataCollection}
                field={{
                  label: `${t("Deadline")} (${t("optional")})`,
                  key: "deadline",
                  sx: {
                    ".MuiInputBase-root, .MuiFormControl-root": {
                      height: "40px!important",
                    },
                    ".MuiInputLabel-shrink": {
                      transform: "translate(14px, -9px) scale(0.75)",
                    },
                    label: {
                      transform: "translate(14px, 11px) scale(1)",
                    },
                  },
                }}
                updateValidationOnChange={updateValidationOnChange}
              />
            </Box>
          </Box>
        ) : (
          element.deadline && (
            <Tooltip title={t("Deadline")} className="flex gap-1 items-center">
              <AccessAlarmIcon fontSize="small" />
              <Typography variant="h6">
                <DateLabel date={element.deadline} />
              </Typography>
            </Tooltip>
          )
        )}
      </Box>
    </Box>
  );
};
